import { useMutation, useQuery } from "react-query";
import { useAxios, ServerError } from "suregifts-component-lib";

export const useRegistryDonate = (urlPath) => {
  var axios = useAxios();
  return useMutation<DonateResult, ServerError, DonateModel>((data) => {
    return axios.post(`/transactions/api/registries/${urlPath}/donate`, data);
  });
};
export const useGetRegistry = (urlPath?: string) => {
  const axios = useAxios();
  return useQuery<RegistryDto, ServerError>({
    queryKey: ["registry", urlPath],
    queryFn: () => axios.get(`/transactions/api/registries/${urlPath}`, {}),
  });
};

export const useGetContributors = (urlPath: string) => {
  const axios = useAxios();
  return useQuery<ContributorDto[], ServerError>({
    initialData: [],
    enabled: !!urlPath,
    queryFn: () => axios.get(`/transactions/api/registries/${urlPath}/recent-contributors`, {}),
  });
};

export interface RegistryDto {
  id: number;
  name: string;
  minAmout: number;
  targetAmount: number;
  urlPath: string;
  startDate: string;
  endDate: string;
  message: string;
  balance: number;
  totalRaised: number;
  imageUrl: string;
  showContributors: boolean;
  currency: string;
  percentCompleted: number;
  started: boolean;
  finished: boolean;
  contributors: number;
  fullUrlPath: string;
}
export interface DonateModel {
  fullName: string;
  email: string;
  isAnonymous: boolean;
  amount: number;
  returnPath: string;
  paymentMethodId: number;
}
export interface DonateResult {
  paymentLink: string;
}

export interface ContributorDto {
  fullName: string;
  email: string;
  amount: number;
  currency: string;
}
