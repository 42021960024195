import { useMutation, useQuery } from "react-query";
import { PageFilter, useAxios, Count, ServerError, VoucherStatusType, getCaptchaToken } from "suregifts-component-lib";

export const useGetVouchers = (params: VoucherFilter) => {
  const axios = useAxios();
  return useQuery<Count<VoucherData>, ServerError>({
    queryKey: ["vouchers", params],
    keepPreviousData: true,
    initialData: { total: 0, items: [] },
    queryFn: () =>
      axios.get("/vouchers/api", {
        params,
      }),
  });
};
export const useGetVoucherById = (handleError = true) => {
  const axios = useAxios(handleError);
  return useMutation<VoucherInfoDto, ServerError, string>((voucherCode) => {
    return axios.get(`/vouchers/api/${voucherCode}`);
  });
};
export const useAddVoucher = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, AddVoucherModel>((model) => {
    return axios.post(`/vouchers/api/add`, model);
  });
};
export const useCheckBalanceVoucher = () => {
  var axios = useAxios();
  return useMutation<VoucherInfoDto, ServerError, CheckVoucherBlanceModel>(async (model) => {
    return axios.post(`/vouchers/api/check-voucher`, model, {
      headers: { Captcha: await getCaptchaToken() },
    });
  });
};

export const useTransferVoucher = () => {
  var axios = useAxios();
  return useMutation<any, ServerError, VoucherTransferModel>(async (model) => {
    return axios.post(`/vouchers/api/transfer`, model, {
      headers: { Captcha: await getCaptchaToken() },
    });
  });
};
export const useSwapVoucher = () => {
  var axios = useAxios();
  return useMutation<VoucherInfoDto, ServerError, VoucherSwapModel>((model) => {
    return axios.post(`/vouchers/api/swap`, model);
  });
};
export const useSplitVoucher = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, VoucherSplitModel>((model) => {
    return axios.post(`/vouchers/api/splt`, model);
  });
};
export const useMergeVoucher = () => {
  var axios = useAxios();
  return useMutation<VoucherInfoDto, ServerError, VoucherMergeModel>((model) => {
    return axios.post(`/vouchers/api/merge`, model);
  });
};

export const useReleaseVoucher = () => {
  var axios = useAxios();
  return useMutation<VoucherInfoDto, ServerError, ReleaseVoucherModel>((model) => {
    return axios.post(`/vouchers/api/release`, model);
  });
};
export const useConvertVoucher = () => {
  var axios = useAxios();
  return useMutation<VoucherInfoDto, ServerError, string>((voucherCode) => {
    return axios.post(`/vouchers/api/convert`, { voucherCode });
  });
};

export interface VoucherFilter extends PageFilter {
  active: boolean;
}

export interface VoucherData {
  serialNumber: string;
  typeId: number;
  type: string;
  merchantName: string;
  voucherCode: string;
  value: string;
  expiryDate: string;
  isExpired: boolean;
  balance: string;
  statusId: VoucherStatusType;
  status: string;
  giftId: string;
  imageUrl: string;
  countries: string;
  isSuregifts: boolean;
  isLegacy: boolean;
}
export interface VoucherInfoDto extends VoucherData {
  operations: VoucherOperation[];
}

export interface VoucherOperation {
  narration: string;
  date: string;
  amount: string;
  fee: String;
}
export interface CheckVoucherBlanceModel {
  voucherCode: string;
  pin: string;
}
export interface VoucherTransferModel {
  voucherCode: string;
  pin: string;
  email: string;
  name: string;
}
export interface AddVoucherModel {
  voucherCode: string;
  pin: string;
}

export interface ReleaseVoucherModel {
  voucherCode: string;
  pin: string;
}

export interface VoucherMergeModel {
  codes: string[];
}
export interface VoucherSplitModel {
  voucherCode: string;
  values: string[];
}
export interface VoucherSwapModel {
  giftId: string;
  voucherCode: string;
  agreed: boolean;
}
