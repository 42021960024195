import * as React from "react";
export const DonateHandIcon = (props) => (
  <svg width={27} height={27} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="heart (3) 1" clipPath="url(#clip0_4165_19464)">
      <g id="Group">
        <g id="Group_2">
          <path
            id="Vector"
            d="M2.48132 15.9258H0.793823C0.356603 15.9258 0.00280762 16.2796 0.00280762 16.7168V26.209C0.00280762 26.6462 0.356603 27 0.793823 27H2.48132C3.78987 27 4.85437 25.9355 4.85437 24.627V18.2988C4.85437 16.9903 3.78993 15.9258 2.48132 15.9258Z"
            fill="#009E5F"
          />
        </g>
      </g>
      <g id="Group_3">
        <g id="Group_4">
          <path
            id="Vector_2"
            d="M26.7655 17.459C26.6705 17.3009 26.544 17.1743 26.4016 17.0635C25.8192 16.554 24.8379 16.6021 24.266 17.2218L20.6747 21.3667L20.5639 21.4932C20.121 21.9836 19.4882 22.2526 18.8238 22.2526H12.6855C12.2425 22.2526 11.8945 21.9045 11.8945 21.4616C11.8945 21.0186 12.2425 20.6706 12.6855 20.6706H17.5107C18.3808 20.6706 19.0927 19.9587 19.0927 19.0886V19.0728C19.0769 18.2026 18.3808 17.5065 17.5107 17.5065H14.6472C14.1728 17.5065 13.6644 17.3324 13.255 16.9844C11.325 15.2916 8.50892 15.1809 6.43646 16.6206V26.35C8.00267 26.7772 9.61635 26.9987 11.2458 26.9987H18.3017C20.0419 26.9987 21.6872 26.176 22.7314 24.7838L26.5283 19.7213C27.0822 18.9942 27.1239 17.997 26.7655 17.459Z"
            fill="#009E5F"
          />
        </g>
      </g>
      <g id="Group_5">
        <g id="Group_6">
          <path
            id="Vector_3"
            d="M21.2131 1.52903C20.3358 0.543006 19.1195 0 17.7885 0C16.3029 0 15.0151 0.702475 14.0643 2.03143C13.993 2.13105 13.9263 2.23072 13.8638 2.32933C13.8014 2.23066 13.7347 2.13105 13.6634 2.03143C12.7126 0.702475 11.4248 0 9.93918 0C8.60816 0 7.39195 0.543006 6.51466 1.52898C5.68209 2.4647 5.22351 3.71561 5.22351 5.05132C5.22351 6.50283 5.78677 7.84223 6.99597 9.26659C8.05113 10.5093 9.56455 11.7867 11.3169 13.2658C11.9645 13.8123 12.6342 14.3776 13.3458 14.9943C13.4944 15.1231 13.6791 15.1876 13.8638 15.1876C14.0485 15.1876 14.2332 15.1231 14.3819 14.9943C15.0934 14.3777 15.7629 13.8125 16.4105 13.266C17.536 12.3161 18.5079 11.4957 19.3514 10.7C20.9889 9.15495 22.5042 7.40127 22.5042 5.05137C22.5042 3.71566 22.0457 2.46475 21.2131 1.52903Z"
            fill="#009E5F"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4165_19464">
        <rect width={27} height={27} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const TargetIcon = (props) => (
  <svg width={15} height={15} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="target 1" clipPath="url(#clip0_4165_19476)">
      <g id="Icon">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1206 6.76555C12.06 6.51367 12.215 6.26055 12.4663 6.19992C12.7175 6.13867 12.9713 6.29367 13.0319 6.54555C13.145 7.0143 13.205 7.50367 13.205 8.0068C13.205 11.4349 10.4219 14.2187 6.99313 14.2187C3.565 14.2187 0.78125 11.4349 0.78125 8.0068C0.78125 4.57805 3.565 1.79492 6.99313 1.79492C7.49625 1.79492 7.98563 1.85492 8.45438 1.96805C8.70625 2.02867 8.86125 2.28242 8.8 2.53367C8.73938 2.78492 8.48625 2.93992 8.23438 2.8793C7.83625 2.78305 7.42063 2.73242 6.99313 2.73242C4.08188 2.73242 1.71875 5.09555 1.71875 8.0068C1.71875 10.918 4.08188 13.2812 6.99313 13.2812C9.90438 13.2812 12.2675 10.918 12.2675 8.0068C12.2675 7.5793 12.2169 7.16367 12.1206 6.76555Z"
          fill="#EC1A3C"
        />
        <path
          id="Vector_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.22749 8.00781C9.22749 7.74906 9.43749 7.53906 9.69624 7.53906C9.95437 7.53906 10.165 7.74906 10.165 8.00781C10.165 9.75781 8.74374 11.1791 6.99312 11.1791C5.24312 11.1791 3.82187 9.75781 3.82187 8.00781C3.82187 6.25719 5.24312 4.83594 6.99312 4.83594C7.25187 4.83594 7.46187 5.04656 7.46187 5.30469C7.46187 5.56344 7.25187 5.77344 6.99312 5.77344C5.75999 5.77344 4.75937 6.77469 4.75937 8.00781C4.75937 9.24094 5.75999 10.2416 6.99312 10.2416C8.22624 10.2416 9.22749 9.24094 9.22749 8.00781Z"
          fill="#EC1A3C"
        />
        <path
          id="Vector_3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0812 3.4926L12.1706 5.40385C12.0825 5.49198 11.9631 5.54135 11.8387 5.54135H9.92749C9.66874 5.54135 9.45874 5.33135 9.45874 5.0726V3.16135C9.45874 3.03698 9.50812 2.9176 9.59624 2.82948L11.5075 0.918853C11.6225 0.803853 11.7887 0.756353 11.9469 0.793853C12.105 0.831353 12.2325 0.947603 12.2837 1.10198L12.6875 2.3126L13.8981 2.71635C14.0525 2.7676 14.1687 2.8951 14.2062 3.05323C14.2437 3.21135 14.1962 3.3776 14.0812 3.4926ZM12.8825 3.36635L12.1687 3.12823C12.0287 3.08135 11.9187 2.97135 11.8719 2.83135L11.6337 2.1176L10.3962 3.3551V4.60385H11.645L12.8825 3.36635Z"
          fill="#EC1A3C"
        />
        <path
          id="Vector_4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.59625 4.74086C9.77937 4.55773 10.0762 4.55773 10.2594 4.74086C10.4425 4.92398 10.4425 5.22086 10.2594 5.40398L7.3925 8.27023C7.20937 8.45336 6.9125 8.45336 6.73 8.27023C6.54687 8.08773 6.54687 7.79086 6.73 7.60773L9.59625 4.74086Z"
          fill="#EC1A3C"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4165_19476">
        <rect width={15} height={15} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
