import { Stack, Divider, Box, Typography, Card } from "@mui/material";
import { PageHeaderTitle } from "suregifts-component-lib";
import { useCartContext } from "./CartContext";
import { useGlobalContext } from "../../../contexts/GlobalContext";

export const OrderAmountSection = () => {
  const { cart } = useGlobalContext();
  const { calcAmount } = useCartContext();
  return (
    <div>
      <PageHeaderTitle title="Order Amount" />
      <Card sx={{ padding: "25px 20px", borderRadius: "20px", marginTop: "20px", backgroundColor: "primary.main" }}>
        <Stack spacing={"20px"}>
          <InlineAmountView title={"Subtotal"} info={cart?.subTotal} />
          <InlineAmountView title={"Shipping"} info={cart?.shipping} />
          <InlineAmountView title={"Printing"} info={cart?.printingFee} />
          <InlineAmountView color="error.main" title={"Service Fee"} info={cart?.fee} />
          <InlineAmountView color="success.main" title={"Coupon"} info={`-${cart?.coupon}`} />
          <InlineAmountView color="success.main" title={"Discount"} info={`-${cart?.discount}`} />
          <InlineAmountView color="success.main" title={"Wallet"} info={`-${calcAmount.walletDeduction}`} />
          <Divider flexItem />
          <InlineAmountView title={"Total"} info={calcAmount.amountDue} fontSize="18px" fontWeight={700} />
        </Stack>
      </Card>
    </div>
  );
};

type InlineAmountViewProps = {
  title;
  info;
  color?: string;
  fontSize?: string;
  fontWeight?: string | number;
};
const InlineAmountView = ({ title, info, color, fontSize, fontWeight }: InlineAmountViewProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        "& .MuiTypography-root.MuiTypography-body1": {
          fontSize: fontSize || "14px",
          fontWeight: fontWeight || "500",
          color: color,
        },
      }}
    >
      <Typography>{title}</Typography>
      <Typography>{info}</Typography>
    </Box>
  );
};
