import { Box, Button, Container, Divider, Grid } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { RegistryDto, useGetRegistry } from "../services/registry";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import { formatMoney, useDocumentTitle } from "suregifts-component-lib";
import { DonateHandIcon, TargetIcon } from "./Icons";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ContributorsView from "./ContributorsView";

function RegistryScreen() {
  const { urlPath } = useParams();
  const { data } = useGetRegistry(urlPath);
  useDocumentTitle(data?.name ?? "Registry");

  if (!data) return null;
  return (
    <Container sx={{ marginY: "40px" }}>
      <Grid container spacing={"1rem"}>
        <Grid item lg={8}>
          <img
            src="/assets/images/registry.png"
            style={{
              width: "100%",
              minHeight: "380px",
              maxHeight: "380px",
              objectFit: "cover",
              borderRadius: "16px",
            }}
            alt=""
          />
          <h1 style={{ fontSize: "32px", fontWeight: 600 }}>{data.name}</h1>
          <p style={{ fontSize: "14px", fontWeight: 500, lineHeight: "20px" }}>{data.message}</p>
          <Divider sx={{ marginY: "32px" }} />
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <span
              style={{ padding: "16px 16px", backgroundColor: "#f2f2f2", borderRadius: "6px", display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <ShareIcon style={{ color: "#b2b2b2" }} />
            </span>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "90px",
                px: "16px",
                borderRadius: "0.5rem",
                border: "1px solid #DDDDDD",
                fontSize: "14px",
              }}
            >
              {data?.fullUrlPath}
              <button style={{ background: "none", border: "none", cursor: "pointer" }}>
                <ContentCopyIcon color="error" />
              </button>
            </Box>
          </div>
        </Grid>
        <Grid item lg={4}>
          <RegistryInfoView data={data} />
          {data.showContributors && <ContributorsView />}
        </Grid>
      </Grid>
    </Container>
  );
}
type RegistryInfoViewProps = {
  data: RegistryDto;
};
const RegistryInfoView = ({ data }: RegistryInfoViewProps) => {
  return (
    <Box sx={{ boxShadow: "1", padding: "24px", borderRadius: "16px", backgroundColor: "primary.main" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <div style={{ fontSize: "20px", lineHeight: "25px", fontWeight: 700 }}>
            {data.currency}
            {formatMoney(data.totalRaised)} raised
          </div>
          {data.targetAmount && (
            <div style={{ fontSize: "14px", fontWeight: 500, color: "#6F6F6F", display: "flex", alignItems: "center", gap: "5px", marginTop: "5px" }}>
              <TargetIcon /> Target: {data.currency}
              {formatMoney(data.targetAmount)}
            </div>
          )}
        </div>
        <div style={{ border: "1px solid #DDDDDD", padding: "8px", borderRadius: "8px" }}>
          <DonateHandIcon />
        </div>
      </div>
      <ProgressBar percentCompleted={data.percentCompleted} />
      <div style={{ display: "grid", gap: "8px" }}>
        <RegistryAttributeView />
        <RegistryAttributeView />
      </div>

      <Button color="error" sx={{ padding: "16px", fontWeight: 700, fontSize: "15px", marginTop: "30px", display: "block", borderRadius: "50vh" }} fullWidth>
        Donate
      </Button>
    </Box>
  );
};
const RegistryAttributeView = () => {
  return (
    <Box style={{ display: "flex", fontSize: "14px", fontWeight: "500", alignItems: "center", gap: "8px" }}>
      <Box
        sx={{
          width: "34px",
          bgcolor: "primary.light",
          height: "34px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
        }}
      >
        <CalendarTodayIcon color="error" sx={{ width: 16, height: 16 }} />
      </Box>
      <span>Ends 21/11/2024</span>
    </Box>
  );
};
const ProgressBar = ({ percentCompleted }) => {
  if (typeof percentCompleted !== "undefined") return null;
  return (
    <Box
      sx={{
        backgroundColor: "#F2F2F2",
        width: "100%",
        height: "10px",
        borderRadius: "30px",
        my: "24px",
        "& > div": {
          height: "100%",
          borderRadius: "30px",
          bgcolor: "error.main",
          width: `${percentCompleted}%`,
        },
      }}
    >
      <div></div>
    </Box>
  );
};

export default RegistryScreen;
